import BookingForm from "@/components/BookingForm";
import CourseBadges from "@/components/CourseBadges";
import CoursePage from "@/components/CoursePage";
import Para from "@/components/Para";
import SpacePic from "@/components/SpacePic";
import { spacecatTrainingEnquiriesLink } from "@/reform";
import { Box } from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";

export function EffectiveTypeScriptAbstract(): ReactElement {
  return (
    <Box>
      <CourseBadges days={1} />
      <Para>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio, eum
        eius ipsa sit vel possimus autem dolore nemo quasi at doloremque
        reiciendis, ipsam animi excepturi quisquam ut? Tempora, odit mollitia!
      </Para>
    </Box>
  );
}

function EffectiveTypeScriptOutline(): ReactElement {
  return <p>Coming soon...</p>;
}

export default function EffectiveTypeScriptPage(
  _props: PageProps
): ReactElement {
  return (
    <CoursePage
      title="Effective TypeScript"
      category="typescript"
      accent="orange"
      abstract={<EffectiveTypeScriptAbstract />}
      bookingForm={<BookingForm /* defaultCourse="effective-typescript" */ />}
      picture={<SpacePic name="saucer" maxW="96" mx="auto" />}
      outline={<EffectiveTypeScriptOutline />}
    />
  );
}
